@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~@flaticon/flaticon-uicons/css/all/all';
@import url('node_modules/@leapfinance/geebee-component-library/src/styles/tailwind.css');
@layer components {
  .active-header {
    border-bottom: 2px solid blue;
  }
}
html,
body {
  padding: 0;
  margin: 0;
}


p,
a,
ul,
li {
  font-family: Montserrat, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.PrivatePickersFadeTransitionGroup-root {
  font-size: 0.8rem;
}

.datepickerContainer {
  border: 1px solid #cccccc !important;
  border-radius: 4px !important;
}

/* Hides the scrollbar inside this particular element with this ID */
#CF_USP_FILTER_CHIPS_CONTAINER::-webkit-scrollbar {
  display: none;
}

#COURSE_DETAILS_NAVBAR::-webkit-scrollbar {
  display: none;
}

@media print {
  body {
    -webkit-print-color-adjust: exact; /* Chrome, Safari */
    print-color-adjust: exact; /* Firefox */
    /* padding-top: 20mm;  */
    /* background: #ffffff; Set your desired background color */
  }
  .page-break {
    page-break-after: always; /* Always insert a page break after this element */
  }
  .no-page-break-inside {
    page-break-inside: avoid; /* Avoid breaking inside this element if possible */
  }
  @page {
    size: auto;
    margin: 0;
    /* padding-top: 20mm;  */
  }
}
@media (-webkit-min-device-pixel-ratio: 0) {
  body {
    -webkit-print-color-adjust: exact; /* Adjust color for WebKit */
  }
  @page {
    size: auto;
    margin: 0;
    padding-top: 20mm;
  }
}

.selected-courses-side-panel::-webkit-scrollbar {
  margin-top: 4;
  width: 6px;
}
.selected-courses-side-panel::-webkit-scrollbar-thumb {
  border-radius: 8px;
  width: 6px;
  background-color: #dbdbdb;
}

.helper-text-container > h1 {
  font-family: 'Noto Sans';
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: #545276;
}

.helper-text-container > ul,
.helper-text-container li,
.helper-text-container > p,
.helper-text-container > a {
  font-family: 'Noto Sans';
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #545276;
}

.helper-text-container > a {
  text-decoration: underline;
  color: #443eff;
}
.helper-text-container > ul,
.helper-text-container > li {
  list-style: inside;
}
